import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { Button} from "@material-ui/core";
import { Menu, MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState} from "react";
import { useMediaQuery } from '@mui/material';

import {
  CustomModal,
  SingleInputField,
  TwoInputField,
} from "../../../components";
import { inventoryPodData } from "../../../assets/lib/vendor";
import { inventoryProductsData } from "../../../assets/lib/inventoryProduts";

const customStyle = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
});

const UpdateInventory = (props) => {
  const {
    setIsModalOpen,
    isModalOpen,
    handleSubmitModal,
    inventoryState,
    getInventoryInput,
    // errorFields,
    loadingModal,

    productMenu,
    setProductMenu,
    productData
  } = props;
  const classes = customStyle();

const [anchorElProducts, setAnchorElProducts] = useState(null);
const [productPrice, setProductPrice] = useState(null);
const isPhone = useMediaQuery('(max-width: 390px)');


const onChangeMenuItemProducts = (item) => {
  //console.log("price of that item:",item.unitPrice)
  setProductMenu(item);
  setProductPrice(item.unitPrice)
  //console.log("Fianl product data",productData)
  setAnchorElProducts(null);
  
};
//console.log("pmenu",inventoryState)
  return (
    <CustomModal
      heading={"Update Inventory"}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onSubmit={handleSubmitModal}
      showLoading={loadingModal}
    >
      <div className={classes.formContainer}>
      <div className={classes.formGroup}>
              <span className={classes.formLabel}><b>Products :</b></span>
              <Button
            
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={(event) => setAnchorElProducts(event.currentTarget)}
                variant="contained"
                color="primary"
                style={{fontSize:isPhone?"12px":"16px",textTransform: "none",marginLeft:"20px",width:"200px" }}
                endIcon={<KeyboardArrowDownIcon />}
              >
               {inventoryState?.productName}
            
              </Button>
         
            </div>

        <SingleInputField
          content={inventoryProductsData.expiry}
          value={inventoryState.expiry}
          style={{width:"100px"}}
           //errorFields={errorFields}
           getInputData={getInventoryInput}   
           isEditable={false}     />

        <SingleInputField
          content={inventoryProductsData.quantity}
          value={inventoryState.quantity}
          style={{width:"100px"}}
           //errorFields={errorFields}
           getInputData={getInventoryInput}   />     

          <SingleInputField
          content={inventoryProductsData.unitPrice}
          value={inventoryState.unitPrice}
          //errorFields={errorFields}
          getInputData={getInventoryInput}  
            />   
      </div>
    </CustomModal>
  );
};

export default UpdateInventory;
