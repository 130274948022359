import React from "react";
import { useEffect, useState } from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";
import { Menu, MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  CustomModal,
  SingleInputField,
  TwoInputField,
} from "../../../components";
import { inventoryProductsData } from "../../../assets/lib/inventoryProduts";
import { useMediaQuery } from '@mui/material';

const customStyle = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
});

const AddInventory = (props) => {


  const {

    isModalOpenForProduct,
    setIsModalOpenForProduct,
    handleSubmitModalforProducts,
    productState,
    getProductInput,
    // errorFields,
    loadingModal,
    productMenu,
    setProductMenu,
    productData
  } = props;

  const classes = customStyle();
  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const [productPrice, setProductPrice] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [price, setPrice] = useState(null);
  const isPhone = useMediaQuery('(max-width: 390px)');


  const onChangeMenuItemProducts = (item) => {
    //console.log("price of that item:",item.unitPrice)
    setProductMenu(item);
    setProductPrice(item.unitPrice)
    //console.log("Fianl product data",productData)
    setAnchorElProducts(null);
    
  };

  useEffect(() => {
    //console.log("Initial product menu",productMenu)
    //console.log("Initial product data",productData)
    if (productData.length > 0) {
        setProductMenu(productData[0]);
    }
  }, [productData]);
   var date = new Date();
   date.setDate(date.getDate() + productMenu.expiry)

  return (
    
    <CustomModal
      heading={"Add Products"}
      open={isModalOpenForProduct}
      onClose={() => setIsModalOpenForProduct(false)}
      onSubmit={handleSubmitModalforProducts}
      showLoading={loadingModal}
    >
      <div className={classes.formContainer}>
            <div className={classes.formGroup}>
              <span className={classes.formLabel} style={{fontSize:isPhone?"12px":"inherit"}}><b>Products :</b></span>
              <Button
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={(event) => setAnchorElProducts(event.currentTarget)}
                variant="contained"
                color="primary"
                style={{ textTransform: "none", fontSize:isPhone?"12px":"16px",marginLeft:"20px",width:"200px" }}
                endIcon={<KeyboardArrowDownIcon />}
              >
               {productMenu?.productName}
              </Button>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorElProducts}
                keepMounted
                open={Boolean(anchorElProducts)}
                onClose={() => setAnchorElProducts(null)}
              >
                {productData.map((product, index) => (
                  <MenuItem
                    onClick={() => onChangeMenuItemProducts(product)}
                    key={index}
                    
                    getInputData={productPrice}
                  >
                   {product?.productName}
               
                  </MenuItem>
                ))
                 }               
              </Menu>
            </div>
          
         <TwoInputField
          content={inventoryProductsData.quantity}
          value={productState.quantity}

          secondContent={inventoryProductsData.expiry}
          secondValue={date.toDateString()}
          //errorFields={errorFields}
          getInputData={getProductInput}
          
        />
         <SingleInputField
            content={inventoryProductsData.unitPrice}
            value={productPrice*productState.quantity}
            style={{width:"100px"}}
            isEditable={false}
          
           //errorFields={errorFields}
          getInputData={productPrice*productState.quantity}
        />
      </div>
    </CustomModal>
     
  );
};

export default AddInventory;
