import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from "@mui/material/Box";
import { CustomTable, SearchTableInput } from "../../../components";
import ProductsModal from "./ProductsModal";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { routes } from "../../../routing/routes";
import { productTableHeading } from "../../../assets/lib/product";
import { useMediaQuery } from '@mui/material';

const customStyle = makeStyles({
  root: {
    padding: "20px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  buttonContainer: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  actionBtn: {
    display: "flex",
    gap: "30px",
  },
  '@media (max-width: 390px)': {
    root: {
      padding: "10px 20px",
      width: "80%",
      display: "flex",
      flexDirection: "column",
      gap: "30px",
    },
    buttonContainer: {
      height: "50px",
      display: "flex",
      justifyContent: "space-between",
    },
    actionBtn: {
      display: "flex",
      gap: "10px",
    },
  },
});

const Layout = (props) => {
  const {
    productTableData,
    fetchPodNumber,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    isModalOpen,
    setIsModalOpen,
    handleSubmitModal,
    prodState,
    getProductInput,
    errorFields,
    loadingModal,
    campusData,
    campusMenu,
    setCampusMenu,
    vendorData,
    vendorMenu,
    setVendorMenu,
    vpdata,
    gid,   
    handledelete
  } = props;
  const classes = customStyle();
  const navigate = useNavigate();
  const isPhone = useMediaQuery('(max-width: 390px)');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] =useState(5);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <div className={classes.actionBtn}>
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#FF5722",
              padding: "12px 24px",
              borderRadius: "10px",
              marginLeft:isPhone?"-10px":"900px",
              fontSize: isPhone? "8px":"12px" ,
              marginTop:isPhone? "0px":"inherit" 
            }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Add Product
          </Button>
        </div>
      </div>
      { vpdata.length > 0 && 
    <Paper sx={{ width: '100%', overflow: 'hidden',marginLeft:"-12px",marginTop:"-20px"}} >
       <Box height={20} />
      <TableContainer sx={{ maxHeight: 350,marginTop:"-20px" }}>
        <Table stickyHeader aria-label="sticky table" >
         <TableHead >
         <TableRow >
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)",fontSize:isPhone?"12px":"inherit"}}>
              Product ID 
             </TableCell>
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)",fontSize:isPhone?"12px":"inherit" }}>
             Product Name
             </TableCell>
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)",fontSize:isPhone?"12px":"inherit" }}>
             Unit Price
             </TableCell>
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)",fontSize:isPhone?"12px":"inherit" }}>
             Expiry(Days)
             </TableCell>
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)",fontSize:isPhone?"12px":"inherit" }}>
             Action
             </TableCell>
         </TableRow>
       </TableHead>
       <TableBody>
        {vpdata
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
             return (
               <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                     <TableCell  align="center" style={{fontSize:isPhone?"10px":"inherit"}} >
                      {row.productId}
                     </TableCell>
                     <TableCell align="center" style={{fontSize:isPhone?"10px":"inherit"}} >
                      {row.productName}
                     </TableCell>
                     <TableCell  align="center" style={{fontSize:isPhone?"10px":"inherit"}} >
                     {row.unitPrice}
                     </TableCell>
                     <TableCell align="center" style={{fontSize:isPhone?"10px":"inherit"}}>
                    {row.expiry}
                     </TableCell>
                     <TableCell align="center" style={{fontSize:isPhone?"10px":"inherit"}}>
                     <Button
                      variant="contained"
                      style={{
                        color: "white",
                        backgroundColor: "#FF5722",
                        borderRadius: "10px",
                        fontSize:isPhone?"12px":"inherit"
                      }}
                      onClick={() => {
                        handledelete(row); 
                      }}
                    >
                      Delete
                    </Button>
                     </TableCell>
                  </TableRow>
                );
              })
              } 
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5,10,20]}
        component="div"
        count={vpdata?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
}
      <ProductsModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        handleSubmitModal={handleSubmitModal}
        prodState={prodState}
        getProductInput={getProductInput}
        errorFields={errorFields}
        loadingModal={loadingModal}
        campusData={campusData}
        campusMenu={campusMenu}
        setCampusMenu={setCampusMenu}
        vendorData={vendorData}
        vendorMenu={vendorMenu}
        setVendorMenu={setVendorMenu}
        gid={gid}
      />
    </div>
  );
};

export default Layout;
